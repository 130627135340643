import React, { useState, useRef } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Protect from 'react-app-protect';

const RichTextTemplate = (props) => {
  if (!props) return null;

  const node_locale = props.pageContext.node_locale;
  const translation = props.pageContext.translation;

  const pageContent = props.pageContext.data.content;
  const footerContent = props.pageContext.footer.node;
  const headerContent = props.pageContext.header.node;

  const localSeo = props.pageContext.data.seo && props.pageContext.data.seo;
  const slug = props.pageContext.data.slug;
  let noHeaderFooter = false;
  if (
    slug == '/cms/agb' ||
    slug == '/en-de/cms/terms' ||
    slug == '/cms/ueber' ||
    slug == '/en-de/cms/about' ||
    slug == '/cms/impressum' ||
    slug == '/en-de/cms/imprint' ||
    slug == '/cms/datenschutz' ||
    slug == '/en-de/cms/privacy'
  ) {
    noHeaderFooter = true;
  }
  return (
    <>
      {noHeaderFooter
        ? typeof window !== 'undefined' && (
            <Layout
              themeColor="mode--light"
              currentLocation={props.path}
              currentNodeLocale={node_locale}
              footerContent={footerContent}
              headerContent={headerContent}
              translation={translation}
              excludeComponents
            >
              {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

              <section className="section">
                <div className="container">
                  <div className="legalTerms">{documentToReactComponents(pageContent.json)}</div>
                </div>
              </section>
            </Layout>
          )
        : typeof window !== 'undefined' && (
            <Protect sha512="5F8A01670BDECBAFD1D1D5D23E3CB2205B65FE9BABA76542EDB8B36EDD69215E8042015790D3ED2C1A6E3CB949966991C8BECF34EEB02ABA9FF1EDE860EB3E38">
              <Layout
                themeColor="mode--light"
                currentLocation={props.path}
                currentNodeLocale={node_locale}
                footerContent={footerContent}
                headerContent={headerContent}
                translation={translation}
              >
                {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

                <section className="section">
                  <div className="container">
                    <div className="legalTerms">{documentToReactComponents(pageContent.json)}</div>
                  </div>
                </section>
              </Layout>
            </Protect>
          )}
    </>
  );
};

export default RichTextTemplate;
